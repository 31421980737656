import React, { useEffect, useState, useCallback } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL, HttpResponse, HttpStatus } from '../../api/default';
import { useAuthContext } from '../../contexts/AuthContext';
import CardCourses from '../../components/CardCourses';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons';
import Navbar from 'react-bootstrap/Navbar';
import Avatar from 'react-avatar';

const BookmarksPage = () => {
    const [userData, setUserData] = useState({ name: "" });
    const [data, setData] = useState({});
    const [isFetched, setIsFetched] = useState(false);
    const navigate = useNavigate();
    const { token, logged, user } = useAuthContext();

    useEffect(() => {
        const userData = localStorage.getItem('userData');
        if (userData != null)
            setUserData(JSON.parse(userData));
    }, []);

    const getBookmarks = useCallback(async () => {
        let url = `${BASE_URL}/courses/favorites`;
        try {
            const options = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    jwt: token
                }
            };

            let data = { "results": [] };

            while (true) {
                const response = await fetch(url, options);

                if (response.ok) {
                    const jsonData = await response.json();
                    data["results"] = data["results"].concat(jsonData.results);

                    if (jsonData.next == null) {
                        break;
                    }

                    url = jsonData.next;

                    if (url.includes("http:")) {
                        url = url.replace("http:", "https:");
                    }
                } else {
                    throw new Error("Error on getBookmarks()");
                }
            }

            return new HttpResponse(HttpStatus.OK, data);
        } catch (error) {
            console.warn(error);
            return new HttpResponse(HttpStatus.ERROR, null);
        }
    }, [token]); // Adicione 'token' como dependência, se necessário

    useEffect(() => {
        const getStudentCourses = async () => {
            setIsFetched(false);
            try {
                const responseCourses = await getBookmarks();
                if (responseCourses.status === HttpStatus.OK) {
                    setData(responseCourses.data);
                    setIsFetched(true);
                }
            } catch (err) {
                console.log(err);
            }
        };

        getStudentCourses();
    }, [userData, getBookmarks]); // Adicione 'getBookmarks' ao array de dependências

    return (
        <>
            <Container fluid>
                <Col>
                    <Navbar>
                        {logged && user ? (
                            <p style={{ color: '#0f5b7a' }} className="mt-3 fs-6 fw-bold">
                                &#128075;&nbsp; Oi, {user?.name?.split(' ')[0]}!
                            </p>
                        ) : (
                            <p style={{ color: '#0f5b7a' }} className="mt-3 fs-6 fw-bold">
                                &#128075;&nbsp; BEM-VINDO!
                            </p>
                        )}

                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                {user && user.photo ? <img src={user.photo} style={{ width: '50px', aspectRatio: 1, borderRadius: '50%', objectFit: 'fill', objectPosition: 'center', cursor: 'pointer' }} alt="profile" />
                                    : <Avatar
                                        name={(user?.name && user?.name.split(' ')[0]) || "O i"}
                                        color="#0f5b7a"
                                        size={30}
                                        textSizeRatio={2}
                                        round={true}
                                    />}
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Navbar>

                    <Row className="home-card mt-5">
                        <div className="col">
                            <h1 className="mb-3 fs-5 fw-bold">Cursos favoritos</h1>

                            {isFetched ? (
                                data.results.length ? (
                                    <Row className="g-4">
                                        {data.results.map((course) => (
                                            <Col xs={12} lg={4} key={course.id}>
                                                <Link to={`/student/courses/${course.id}`}>
                                                    <CardCourses teste={course} />
                                                </Link>
                                            </Col>
                                        ))}
                                    </Row>
                                ) : (
                                    <Container fluid>
                                        <div className="d-flex align-items-center justify-content-center" style={{ height: "350px" }}>
                                            <span>
                                                <h1>Você não tem nenhum curso marcado como favorito.</h1>
                                                <p className="fs-5" style={{ color: "#1dbfb0", cursor: "pointer" }} onClick={() => { navigate('/') }}>
                                                    Clique aqui e procure algo do seu interesse <FontAwesomeIcon className="fs-5" style={{ color: "yellow" }} icon={faHandPointLeft} />
                                                </p>
                                            </span>
                                        </div>
                                    </Container>
                                )
                            ) : (
                                <Container fluid>
                                    <div className="d-flex align-items-center justify-content-center" style={{ height: "350px" }}>
                                        <Col className="text-center">
                                            <p className="fs-5">Carregando cursos favoritados.</p>
                                            <Spinner animation="border" variant="success" size="lg" />
                                        </Col>
                                    </div>
                                </Container>
                            )}
                        </div>
                    </Row>
                </Col>
            </Container>
        </>
    )
}

export default BookmarksPage;
